.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    position: relative;
}

.userName {
    display: flex;
    align-items: center;

    span {
        color: var(--color-grey-900, #1E1E1E);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        margin-left: 8px;
    }
}

.pageTitle {
    margin-top: 16px;
    color: var(--color-grey-900, #1E1E1E);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 32px;
    /* 91.667% */
}

.addAwardItem {
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 4px;
    background: rgba(221, 190, 144, 0.15);
    box-shadow: 0px 3px 11px 0px rgba(197, 197, 197, 0.10);
    cursor: pointer;
}

.addAwTitle {
    color: var(--color-grey-900, #1E1E1E);
    margin-bottom: 4px;
    margin-top: 4px;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.addAwDesc {
    color: #000;
    font-feature-settings: 'clig'off, 'liga'off;
    margin-bottom: 16px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    /* 125% */
}

.addAwBtn {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--color-accent-first, #DDBE90);
    background: #FFF;
    color: var(--color-grey-900, #1E1E1E);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    /* 125% */
}

.albumSteps {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 44px;
    overflow-y: auto;
    max-height: calc(100vh - 250px);
    padding-bottom: 16px;
    width: 100%;
}

.sportSelector {
    padding: 7px 0;
    border-radius: 4px;
    border: 1px solid var(--color-grey-400, #E9E9E9);
    background: #FFF;
    width: 100%;

}

.stepsFooter {
    width: calc(100% - 32px);
    bottom: 16px;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 15px;
    background-color: white;

    @media (min-width: 1000px) {
        position: relative;
        width: 100%;
        bottom: 0;
    }

}

.editPhotoBlock {
    display: flex;
    gap: 4px;
    align-items: flex-start;
    padding: 7px;
    border-radius: 4px;
    border: 1px dashed var(--color-grey-400, #E9E9E9);
}

.editPhotoBlockImgContainer {
    position: relative;
}

.editPhotoBlockImg {
    border-radius: 4px;
}

.editPhotoBlockImgRemove {
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;
}

.editPhotoBlockList {
    display: flex;
    gap: 4px;
    height: 100px;
    max-width: calc(100% - 104px);
    overflow-x: auto;
}

.addPhotoEdit {
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    border: 1px dashed var(--color-grey-400, #E9E9E9);
    color: var(--color-grey-500, #CDCDCD);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.408px;
    gap: 8px;
    width: 100px;
    height: 100px;
    min-width: 100px;
}

.albumItem {
    margin-bottom: 12px;

}

.albumItemTitle {
    color: var(--color-grey-900, #1E1E1E);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.albumItemBody {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    overflow-x: auto;
    padding-bottom: 20px;
}

.albumItemImage {
    border-radius: 4px;
    object-fit: cover;
    object-position: center;
}

.albumItemAddMore {
    display: flex;
    height: 150px;
    padding: 0px 6.5px 0px 8.5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--color-accent-first, #DDBE90);
    background: rgba(221, 190, 144, 0.15);
    box-shadow: 0px 3px 11px 0px rgba(197, 197, 197, 0.10);
    color: var(--color-grey-900, #1E1E1E);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    /* 100% */
    width: 90px;
    min-width: 90px;
    max-width: 90px;
}

.aRewardBlock {
    width: 100%;
    height: 156px;
    padding: 24px 16px 0 16px;
    border-radius: 4px;
    border: 1px solid var(--color-grey-400, #E9E9E9);
    background: #FFF;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 16px;
    position: relative;
    margin-bottom: 32px;
}

.aRewardBlockLeft {
    height: 100%;
    position: relative;
    z-index: 2;
    padding-right: 8px;
    overflow: hidden;
}

.aRewardBlockCaption {
    display: flex;
    gap: 4px;
    align-items: center;
    color: var(--color-grey-900, #1E1E1E);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    margin-bottom: 16px;
}

.aRewardBlocDate {
    display: inline-flex;
    padding: 4px;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: #F8F8F8;
    color: var(--color-grey-800, #3E3E3E);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    margin-bottom: 8px;
}

.aRewardBlockImage {
    border-radius: 4px 4px 0 0;
    object-fit: cover;
    object-position: center;
}

.aRewardBlockBg {
    width: 217px;
    height: 129px;
    position: absolute;
    right: 119px;
    z-index: 1;
}

.title {
    color: var(--color-grey-900, #1E1E1E);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 23px;
}

.titleEdit {
    color: var(--color-grey-900, #1E1E1E);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.albumPhotos { 
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  .albumPhptosPhoto{
    border-radius: 4px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  .removeBlock{
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
}
.remtitle{
    color: var(--color-grey-700, #818181);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 128.571% */
    letter-spacing: -0.408px;
}

.removeButtons{
    display: flex;
    align-items: center;
    gap: 16px;
    position: fixed;
    bottom: 0;
    width: calc(100% - 32px);
    background-color: white;
    padding-bottom: 16px;
    @media (min-width: 1000px){
        position: sticky;
        bottom: 0;
     }
}