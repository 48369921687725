.statusNav {
    display: flex;
    margin-bottom: 32px;
    margin-top: 16px;
    position: relative;
}

.markPending {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid rgba(221, 190, 144, 1)
}

.markCurrent {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: rgba(221, 190, 144, 1);
    border: 1px solid rgba(221, 190, 144, 1)
}

.markDone {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: rgba(221, 190, 144, 1);
    position: relative;

    &:after {
        content: "";
        position: absolute;
        display: block;
        width: 12px;
        height: 12px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_624_22830)'%3E%3Cpath d='M6.35306 5.64718L6.35306 3.67204C6.35315 3.57839 6.31602 3.48853 6.24985 3.42225C6.18369 3.35597 6.0939 3.31868 6.00024 3.3186C5.90658 3.31852 5.81673 3.35564 5.75045 3.42181C5.68416 3.48798 5.64688 3.57776 5.6468 3.67142L5.6468 5.64655L3.67166 5.64655C3.57792 5.64655 3.48802 5.68379 3.42174 5.75008C3.35545 5.81636 3.31822 5.90626 3.31822 6C3.31822 6.09374 3.35545 6.18364 3.42174 6.24992C3.48802 6.31621 3.57792 6.35345 3.67166 6.35345L5.6468 6.35345L5.6468 8.32858C5.6468 8.42232 5.68403 8.51222 5.75032 8.5785C5.8166 8.64479 5.9065 8.68203 6.00024 8.68203C6.09398 8.68203 6.18388 8.64479 6.25016 8.5785C6.31645 8.51222 6.35369 8.42232 6.35369 8.32858V6.35345H8.32882C8.42256 6.35345 8.51246 6.31621 8.57874 6.24992C8.64503 6.18364 8.68227 6.09374 8.68227 6C8.68227 5.90626 8.64503 5.81636 8.57874 5.75008C8.51246 5.68379 8.42256 5.64655 8.32882 5.64655L6.35306 5.64718Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_624_22830'%3E%3Crect width='8' height='8' fill='white' transform='translate(0.343384 6) rotate(-45)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    }
}

.title {
    color: var(--color-grey-700, #818181);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.408px;
    margin-top: 8px;
}

.activeLine {
    width: 119px;
    height: 2px;
    border-radius: 2px;
    background-color: rgba(221, 190, 144, 1);
    display: block;
    position: absolute;
    right: 0;
    top: 7px
}

.pendingLine {
    width: 119px;
    height: 2px;
    border-radius: 2px;
    background-color: rgba(221, 190, 144, 0.20);
    display: block;
    position: absolute;
    right: 0;
    top: 7px
}

.statusBlock{
    display: flex;
    min-width: 143px;
    margin-right: 8px;
    position: relative;
}
.statusBlock3{
    min-width: unset;
    flex-basis: 100%;

    .activeLine, .pendingLine{
        width: 86%
    }
}
.statusBlockDone{
    width: 48px;
    max-width: 48px;
    min-width: 48px;
}

