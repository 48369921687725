.uIButton.uIButton {
    left: 0;
    right: 0;
    bottom: 0;
    top: initial;
    width: initial;
    place-content: center;
}

.title {
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
}

.root {
    position: relative;
    width: 100%;
    height: 780px;
    align-items: flex-start;
    background-color: #fff;
    overflow: hidden;
}

.frame42 {
    position: absolute;
    left: 1px;
    right: -1px;
    top: 0;
    bottom: 604px;
    align-items: flex-start;
    overflow: hidden;
}

.sL_050721_42770_151 {
    opacity: 0.8;
    position: absolute;
    left: calc(50% - 1px);
    top: -85px;
    width: 676px;
    height: 387px;
    background-image:
        /* webpackIgnore: true */
        url('/images/sL_050721_42770_151.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transform: translateX(-50%);
}

.frame14 {
    position: absolute;
    left: 1px;
    right: -1px;
    top: 77px;
    height: min-content;
    place-content: center;
    align-items: center;
    gap: 222px;
}

.title2 {
    width: min-content;
    height: min-content;
    text-align: center;
    align-items: center;
    white-space: nowrap;
    flex-direction: column;
}

.textBlock {
    color: #000;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
}

.textBlock2 {
    margin-top: 0;
    color: #000;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
}

.frame29 {
    position: absolute;
    left: calc(50% - -1.5px);
    top: 0;
    width: 61px;
    height: 61px;
    transform: translateX(-50%);
}

.icon {
    width: 100%;
    height: 100%;
}

.rectangle8 {
    position: absolute;
    left: 0;
    right: 0;
    top: 176px;
    height: 2px;
    border-radius: 20px;
    background-color: #ddbe90;
}

.title3 {
    position: absolute;
    left: 0;
    top: 200px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
}

.textBlock3 {
    color: #1e1e1e;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
}

.labelWrapper {
    font-size: 20px;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    flex-shrink: 1;
}

.label {
    color: #1e1e1e;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
}

.label2 {
    color: #ddbe90;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
}

.label3 {
    color: #1e1e1e;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
}

.frame43 {
    position: absolute;
    left: 36px;
    top: 270px;
    width: 248px;
    height: 64px;
    align-items: flex-start;
}

.title4 {
    color: #1e1e1e;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    position: absolute;
    left: 0;
    top: 0;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
}

.successSubtitle {
    position: absolute;
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 65px;
    border: 1px solid var(--color-accent-first, #DDBE90);
    z-index: 1;
    left: -32px;
    display: none;
}

.title5 {
    color: #818181;
    font-size: 12px;
    line-height: 18px;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    position: absolute;
    left: 0;
    top: 28px;
    width: 248px;
    height: min-content;
    flex-direction: column;
}

.frame44 {
    position: absolute;
    left: 36px;
    top: 355px;
    width: 248px;
    height: 64px;
    align-items: flex-start;
}

.title6 {
    color: #1e1e1e;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    position: absolute;
    left: 0;
    top: 0;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
}

.title7 {
    color: #818181;
    font-size: 12px;
    line-height: 18px;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    position: absolute;
    left: 0;
    top: 28px;
    width: 248px;
    height: min-content;
    flex-direction: column;
}

.frame45 {
    position: absolute;
    left: 36px;
    top: 438px;
    width: 248px;
    height: 64px;
    align-items: flex-start;
}

.title8 {
    color: #1e1e1e;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    position: absolute;
    left: 0;
    top: 0;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
}

.title9 {
    position: absolute;
    left: 0;
    top: 28px;
    width: 248px;
    height: min-content;
    flex-direction: column;
}

.labelWrapper2 {
    font-size: 12px;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    flex-shrink: 1;
}

.label4 {
    color: #818181;
    font-size: 12px;
    line-height: 18px;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
}

.label5 {
    color: #ddbe90;
    font-size: 12px;
    line-height: 18px;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
}

.label6 {
    color: #818181;
    font-size: 12px;
    line-height: 18px;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
}

.frame41 {
    position: absolute;
    left: 0;
    top: 268px;
    width: 28px;
    height: 229px;
    align-items: flex-start;
}

.frame38 {
    position: absolute;
    left: 0;
    top: 0;
    width: 28px;
    height: 196px;
    align-items: flex-start;
    border-radius: 60px;
    background-color: #ddbe901a;
    overflow: hidden;
}

.frame37 {
    position: absolute;
    left: 4px;
    top: 4px;
    width: 20px;
    height: 20px;
    align-items: flex-start;
    outline: solid 1px #ddbe90;
    outline-offset: -1px;
    border-radius: 65px;
    overflow: hidden;
}

.title10 {
    color: #1e1e1e;
    font-size: 8px;
    line-height: 8px;
    font-weight: 500;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    position: absolute;
    left: 8px;
    top: 6px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
}

.frame40 {
    position: absolute;
    left: 4px;
    top: 88px;
    width: 20px;
    height: 20px;
    align-items: flex-start;
    outline: solid 1px #ddbe90;
    outline-offset: -1px;
    border-radius: 65px;
    overflow: hidden;
}

.title11 {
    color: #1e1e1e;
    font-size: 8px;
    line-height: 8px;
    font-weight: 500;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    position: absolute;
    left: calc(50% - -0.5px);
    top: 6px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
    transform: translateX(-50%);
}

.frame412 {
    position: absolute;
    left: 4px;
    top: 172px;
    width: 20px;
    height: 20px;
    align-items: flex-start;
    outline: solid 1px #ddbe90;
    outline-offset: -1px;
    border-radius: 65px;
    overflow: hidden;
}

.title12 {
    color: #1e1e1e;
    font-size: 8px;
    line-height: 8px;
    font-weight: 500;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    position: absolute;
    left: 50%;
    top: 6px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
    transform: translateX(-50%);
}

.frame402 {
    position: absolute;
    left: 0;
    top: 0;
    width: 28px;
    height: 229px;
    border-radius: 60px;
    background-image: linear-gradient(180deg, rgba(221, 190, 144, 0.10) 89%, #ffff 100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    overflow: hidden;
}

.frame46 {
    position: absolute;
    left: 0;
    right: 0;
    top: 518px;
    height: 74px;
    align-items: flex-start;
    outline: solid 1px #ddbe90;
    outline-offset: -1px;
    outline-style: dashed;
    border-radius: 4px;
    overflow: hidden;
}

.frame48 {
    position: absolute;
    left: calc(50% - -0.5px);
    top: 50%;
    width: 253px;
    height: 42px;
    align-items: flex-start;
    transform: translate(-50%, -50%);
}

.title13 {
    color: #818181;
    font-size: 12px;
    line-height: 18px;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    position: absolute;
    left: calc(50% - -0.5px);
    top: 0;
    width: min-content;
    height: min-content;
    text-align: center;
    align-items: center;
    white-space: nowrap;
    flex-direction: column;
    transform: translateX(-50%);
}

.frame47 {
    position: absolute;
    left: 0;
    top: 22px;
    width: min-content;
    height: min-content;
    place-content: center;
    align-items: center;
    gap: 6px;
}

.title14 {
    color: #818181;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
}

.title15 {
    color: #818181;
    font-size: 12px;
    line-height: 18px;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    width: min-content;
    height: min-content;
    text-align: center;
    align-items: center;
    white-space: nowrap;
    flex-direction: column;
}

.title16 {
    color: #818181;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
}

._24 {
    position: absolute;
    left: 50%;
    top: 608px;
    width: 300px;
    height: min-content;
    text-align: center;
    align-items: center;
    flex-direction: column;
    transform: translateX(-50%);
}

.textBlock4 {
    color: #818181;
    font-size: 8px;
    line-height: 10px;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
}

.textBlock5 {
    margin-top: 0;
}

.labelWrapper3 {
    font-size: 8px;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
    flex-shrink: 1;
}

.label7 {
    color: #818181;
    font-size: 8px;
    line-height: 10px;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
}

.label8 {
    color: #ddbe90;
    font-size: 8px;
    line-height: 10px;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
}

.label9 {
    color: #818181;
    font-size: 9px;
    line-height: 10px;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
}

.label10 {
    color: #ddbe90;
    font-size: 8px;
    line-height: 10px;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
}

.label11 {
    color: #818181;
    font-size: 8px;
    line-height: 10px;
    font-family: Rubik, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif;
}