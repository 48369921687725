.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    position: relative;
}

.userName {
    display: flex;
    align-items: center;

    span {
        color: var(--color-grey-900, #1E1E1E);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        margin-left: 8px;
    }
}

.title {
    color: var(--system-black, #000);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 16px;
}

.search {
    margin-top: 24px;
}

.modalWrapper {
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.stepsFooter {
    width: 100%;
    bottom: 0;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    // position: sticky;
    margin-top: auto;
    background-color: white;
    z-index: 5;

    // @media (min-width: 1000px){
    //     position: fixed;
    //     width: calc(100% - 32px);
    //     bottom: 0px;
    //     padding-bottom: 16px;
    // }
}

.stepLine {
    display: flex;
    gap: 8px;
}

.subtitle {
    color: var(--color-grey-700, #818181);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
    letter-spacing: -0.408px;
}

.registrationSteps {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 20px;
    overflow-y: auto;
    max-height: calc(100vh - 350px);


    @media (min-width: 1000px){
       max-height:  270px
    }
}

.tip {
    color: var(--color-grey-700, #818181);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 150% */

    span {
        font-style: italic;
    }
}

.stepButton {
    display: flex;
    gap: 16px;
}

.sportSelector {
    height: 48px;
    padding: 10px 0;
    border-radius: 4px;
    border: 1px solid var(--color-grey-400, #E9E9E9);
    background: #FFF;
    width: 100%;
}
.sportSelectorMobile{
    height: 48px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid var(--color-grey-400, #E9E9E9);
    background: #FFF;
    width: 100%;
    color: black;
    font-size: 16px;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    outline: none;

    &:after{
        display: inline-block;
    width: 0;
    visibility: hidden;
    content: '>';
    }
}

.line {
    display: flex;
    align-items: center;
    gap: 8px
}

.text {
    color: var(--color-grey-700, #818181);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.separator {
    width: 100%;
    min-height: 1px;
    background: var(--color-grey-400, #E9E9E9);
    margin: 16px 0;
}

.addPhoto {
    cursor: pointer;
    display: flex;
    padding: 25px 0;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    border: 1px dashed var(--color-grey-400, #E9E9E9);
    color: var(--color-grey-500, #CDCDCD);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.408px;
    gap: 8px;
}

.addPhotoEdit {
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    border: 1px dashed var(--color-grey-400, #E9E9E9);
    color: var(--color-grey-500, #CDCDCD);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.408px;
    gap: 8px;
    width: 100px;
    height: 100px;
    min-width: 100px;
}

.promo {
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px dashed var(--color-accent-first, #DDBE90);
    margin-bottom: 40px;
}

.promoIcon {
    color: var(--color-grey-700, #818181);
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 137.5% */
    letter-spacing: -0.408px;
    display: flex;
    padding: 5px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 38px;
    border: 1px solid var(--color-grey-400, #E9E9E9);
    background: #FFF;
}

.promoText {
    display: flex;
    flex-direction: column;
    gap: 8px
}

.promoTextInfo {
    color: var(--color-grey-700, #818181);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 150% */
}

.promoTextLink {
    color: var(--color-accent-first, #DDBE90);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 150% */
}

.modalSuccess {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.successModalIcon {
    margin-bottom: 32px;
}

.successModalHeading {
    color: var(--color-grey-900, #1E1E1E);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 4px;
}

.successModalTitle {
    color: var(--color-grey-900, #1E1E1E);
    text-align: center;
    font-feature-settings: 'clig'off, 'liga'off;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
}

.filterButtonBLock {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--color-grey-700, #818181);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: -0.408px;
}

.filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    border-bottom: 1px solid #E9E9E9;
    margin-bottom: 16px;
}

.changeView {
    display: flex;
    gap: 4px;
    align-items: center;
}

.ap {
    padding: 16px;
}

.awardItemImage {
    max-width: 100%;
    height: auto;
    border-radius: 4px 4px 0 0;
    max-height: 252px;
    object-fit: cover;
    object-position: center;
}

.apMainInfo {
    display: flex;
    gap: 4px;
    width: 100%;
    position: relative;
    top: -10px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid var(--color-grey-400, #E9E9E9);
    background: #FFF;
}

.apMainInfoCup {
    width: 48px;
    min-width: 48px;
    height: 48px;
    color: #000;
    font-size: 26.432px;
    font-style: normal;
    font-weight: 500;
    line-height: 36.344px;
    letter-spacing: -0.674px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.apMainInfoData {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;

}

.apMainInfoDataTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 16px;
}

.apMainInfoDataTopType {
    color: var(--color-grey-700, #818181);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    /* 100% */
}

.apMainInfoDataTopDate {
    display: flex;
    align-items: center;
    gap: 4px;

    span {
        color: var(--color-grey-900, #1E1E1E);
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 10px;
        /* 100% */
    }
}

.apMainInfoDataTitle {
    color: var(--SystemBlack, #000);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 133.333% */
}

.addAwardItem {
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 4px;
    background: rgba(221, 190, 144, 0.15);
    box-shadow: 0px 3px 11px 0px rgba(197, 197, 197, 0.10);
    cursor: pointer;
}

.addAwTitle {
    color: var(--color-grey-900, #1E1E1E);
    margin-bottom: 4px;
    margin-top: 4px;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.addAwDesc {
    color: #000;
    font-feature-settings: 'clig'off, 'liga'off;
    margin-bottom: 16px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    /* 125% */
}

.addAwBtn {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--color-accent-first, #DDBE90);
    background: #FFF;
    color: var(--color-grey-900, #1E1E1E);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    margin-top: auto;
    /* 125% */
}

.profileUserInfo {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.editPhotoBlock {
    display: flex;
    gap: 4px;
    align-items: flex-start;
}

.editPhotoBlockImgContainer {
    position: relative;
}

.editPhotoBlockImg {
    border-radius: 4px;
}

.editPhotoBlockImgRemove {
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;
}

.editPhotoBlockList {
    display: flex;
    gap: 4px;
    height: 100px;
    max-width: calc(100% - 104px);
    overflow-x: auto;
}

.modalEditWrapper {
    width: 100%;
    margin: 0 auto;
    position: relative;
    max-height: calc(100vh - 120px);
    overflow-y: auto;
    padding-top: 20px;
}

.apSportInfo {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-shrink: 1;
}

.apSport {
    display: flex;
    min-height: 118px;
    padding: 8px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 14px;
    border-radius: 4px;
    background: rgba(221, 190, 144, 0.15);
    box-shadow: 0px 3px 11px 0px rgba(197, 197, 197, 0.10);
    flex-basis: 100%;
    align-self: stretch;
}

.apSportTop {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 11px;
}

.apSportTopIcon {}

.apSportTopTitle {
    color: var(--SystemBlack, #000);
    font-feature-settings: 'clig'off, 'liga'off;
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
    line-height: 14px;
    /* 100% */
}

.apSportBottom {
    display: flex;
    gap: 4px;
    flex-direction: column;
}

.apSportBottomCaption {
    color: var(--color-grey-700, #818181);
    font-feature-settings: 'clig'off, 'liga'off;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    /* 120% */
}

.apSportBottomDistance {
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-radius: 4px;
    background: var(--color-accent-first-300, rgba(221, 190, 144, 0.30));
    color: var(--color-grey-900, #1E1E1E);
    text-align: justify;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    /* 100% */
}

.apResult {
    display: flex;
    padding: 16px;
    flex-direction: column;
    height: 118px;
    gap: 8px;
    flex-basis: 100%;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--color-grey-400, #E9E9E9);
    background: #FFF;
    align-self: stretch;
}

.apResultTop {}

.apResultBottom {
    color: var(--color-grey-700, #818181);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    overflow: auto;
    white-space: break-spaces;
}

.apData {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.awardLine {
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 4px;
    border: 1px solid var(--color-grey-400, #E9E9E9);
    background: #FFF;
    box-shadow: 0px 3px 11px 0px rgba(197, 197, 197, 0.10);
}

.awardLineIcon {
    width: 30px;
    min-width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.awardLineData {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: calc(100% - 35px);
}

.awardLineDataCaption {
    color: var(--color-grey-700, #818181);
    font-feature-settings: 'clig'off, 'liga'off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    /* 100% */
}

.awardLineDataText {
    color: var(--color-grey-900, #1E1E1E);
    font-feature-settings: 'clig'off, 'liga'off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.awardLineDataType {
    span {
        display: inline-flex;
        padding: 4px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        color: var(--color-grey-900, #1E1E1E);
        text-align: justify;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 10px;
        /* 100% */
    }
}

.awardLineDataLink {
    display: flex;
    align-items: center;

    span {
        color: var(--color-grey-900, #1E1E1E);
        font-feature-settings: 'clig'off, 'liga'off;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    a {
        display: flex;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
    }
}

.filtersLine {
    display: flex;
    flex-direction: column;
}

.filtersItem {
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-radius: 4px;
    background: var(--color-accent-first-300, rgba(221, 190, 144, 0.30));
    color: var(--color-grey-700, #818181);
    text-align: justify;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    /* 100% */
    letter-spacing: -0.408px;
}
.removeBlock{
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
        width: 100%;
}
.remtitle{
    color: var(--color-grey-700, #818181);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 128.571% */
    letter-spacing: -0.408px;
}

.removeButtons{
    display: flex;
    align-items: center;
    gap: 16px;
    position: fixed;
    bottom: 0;
    width: calc(100% - 32px);
    background-color: white;
    padding-bottom: 16px;

    @media (min-width: 1000px){
       position: relative;
       margin-top: 24px;
       width: 100%;
     }
}
.addForm{
    overflow: hidden;
}