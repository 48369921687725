.share{
width: 100%;
display: flex;
flex-direction: column;
gap: 24px;
}
.allButton{
display: flex;
width: 100%;
padding: 8px 16px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 4px;
border: 1px solid var(--color-accent-first, #DDBE90);
color: var(--color-grey-900, #1E1E1E);
text-align: justify;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px;
background-color: white;

&:disabled{
    opacity: .3;
}
}