.reminder{
    overflow-y: auto;
}
.reminderIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px 0;
    @media (min-width: 1000px){
        margin: 10px;

        svg{
            max-width: 100px;
            max-height: 100px;
        }
    }
}

.reminderTitle {
    color: var(--color-grey-900, #1E1E1E);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 8px;
}

.reminderDesc {
    color: var(--color-grey-900, #1E1E1E);
    text-align: center;
    font-feature-settings: 'clig'off, 'liga'off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 100px;

    @media (min-width: 1000px){
        margin-bottom: 24px;
    }
}

.reminderButtons {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    @media (min-width: 1000px){
        flex-direction: row;
    }
}