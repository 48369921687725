@import '../../../styles/mixins';

.mnInput {
    display: flex;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid var(--color-grey-400, #E9E9E9);
    background: #FFF;
    align-items: center;
    margin-bottom: 24px;
    position: relative;
}

.icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input {
    width: 100%;
    background: transparent;
    border: none;
    box-shadow: none;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.408px;
    outline: none;

    @include placeholder(#CDCDCD);

    @media (min-width: 768px){
        font-size: 14px;
    }
}

.textarea{
    width: 100%;
    background: transparent;
    border: none;
    box-shadow: none;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.408px;
    outline: none;

    @include placeholder(#CDCDCD);

    @media (min-width: 768px){
        font-size: 14px;
    }
}