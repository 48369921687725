.nav {
    display: flex;
    justify-content: space-around;
    border-top: 1px solid var(--color-grey-400, #E9E9E9);
    padding: 5px;
}

.navItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navItemImage{
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
}

.navItemTitle {
    color: var(--color-grey-900, #1E1E1E);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
}