@import "../../../styles/mixins";

.overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.40);
    z-index: 10;
    min-height: -webkit-fill-available;
}

.modalWrapper {
    position: absolute;
    bottom: -20000px;
    width: 100%;
    max-height: 100%;
    max-height: -webkit-fill-available;

    @include transition();

    @media (min-width: 1000px){
        position: absolute;
        width: 600px;
        height: 90vh;
        min-height: 500px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow:hidden;
    }
}

.closeModal {
    border-radius: 200px;
    background: var(--color-grey-400, #E9E9E9);
    width: 32px;
    min-width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalTopLine {
    width: 54px;
    height: 6px;
    flex-shrink: 0;
    border-radius: 38px;
    background: var(--color-grey-400, #E9E9E9);
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    @media (min-width: 1000px){
        display: none;
    }
}

.modalBody {
    border-radius: 20px 20px 0px 0px;
    border: 1px solid var(--color-grey-400, #E9E9E9);
    background: #FFF;
    box-shadow: 0px -4px 24px 0px rgba(144, 144, 144, 0.05);
    display: flex;
    padding: 16px;
    min-height: 400px;
    max-height: 100vh;
    max-height: -webkit-fill-available;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;

    @media (min-width: 1000px){
        border-radius: 20px;
    overflow: hidden;
    max-height: 90vh;
    min-height: unset;
    max-height: -webkit-fill-available;
    }
}

.modalTitle {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
    gap: 16px;

    h3 {
        color: var(--color-grey-900, #1E1E1E);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }
}