@import '../../../styles/mixins';
.checkbox{
    width: 32px;
    height: 16px;
}

.chButton{
position: relative;
background-color: #818181;
border-radius: 16px;
width: 32px;
height: 16px;

@include transition();

span{
    position: absolute;
    background-color: white;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: 2px;
    left: 2px;
    right: unset;
    @include transition();
}
    &.chTrue{
        background-color: #DDBE90;

        span{
            left: 18px;
        }
    }
}