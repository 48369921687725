.footer {
    display: block;
    padding-bottom: 5px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);
}

.content {
    padding: 15px;
    max-width: 500px;
    margin: 0 auto;
    // max-height: calc(100vh - 100px);
    overflow-y: auto;
    position: relative;

    &::after {
        content: "";
        height: 54px;
        display: block;
    }
}

.h1 {
    color: #DDBE90;
    text-align: center;
    font-feature-settings: 'clig'off, 'liga'off;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    /* 143.75% */
    letter-spacing: 0.5px;
    text-transform: uppercase;
    padding: 16px;
    @media(min-height: 800px) {
        padding: 16px;
    }
}

.round {
    width: 24px;
    height: 24px;
    min-width: 24px;
    background: var(--color-grey-400, #E9E9E9);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}

.headerButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.transparentHeaderBtn {
    width: 24px;
    height: 24px;
    min-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pageName {
    color: var(--SystemBlack, #000);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dropDownItem {
    display: flex;
    align-items: center;

    .dropDownItemIcon {
        width: 16px;
        height: 16px;
        min-width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 4px;
    }
}

.addAwardButton {
    position: fixed;
    border: 1px solid var(--color-accent-first, #DDBE90);
    background: var(--color-accent-first, #DDBE90);
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.16);
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 32px;
    bottom: 114px
}

.awardListWrap {
    // max-height: calc(100vh - 312px);
    // overflow-y: auto;
}

.awardList {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: space-between;
}

.awardItem {
    border-radius: 4px;
    border: 1px solid var(--color-grey-400, #E9E9E9);
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
    width: 48.5%;
    cursor: pointer;
}

.awardItemImage {
    max-width: 100%;
    min-width: 100%;
    border-radius: 4px 4px 0 0;
    object-fit: cover;
    object-position: center;
}

.awardItemContent {
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
}

.awardItemDate {
    display: inline-flex;
    padding: 6px;
    align-items: center;
    gap: 6px;
    border-radius: 4px;
    background: #F8F8F8;
    color: var(--color-grey-800, #3E3E3E);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
}

.awardItemTitle {
    color: var(--SystemBlack, #000);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    height: 45px;
    margin-bottom: 4px;
}

.awardItemTypeBlock {
    display: flex;
    width: 159px;
    padding: 8px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 6px;
    border-radius: 4px;
    width: 100%;
    min-height: 77px;
    background: url('data:image/svg+xml,<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="48" height="48" rx="24" fill="%23DDBE90" fill-opacity="0.2"/><path d="M23.5 16.5H21V14H23.5V16.5ZM28.5 14H26V16.5H28.5V14ZM21 26.5H23.5V24H21V26.5ZM33.5 21.5V19H31V21.5H33.5ZM33.5 26.5V24H31V26.5H33.5ZM26 26.5H28.5V24H26V26.5ZM33.5 14H31V16.5H33.5V14ZM26 19V16.5H23.5V19H26ZM18.5 21.5V19H21V16.5H18.5V14H16V34H18.5V24H21V21.5H18.5ZM28.5 24H31V21.5H28.5V24ZM23.5 21.5V24H26V21.5H23.5ZM21 19V21.5H23.5V19H21ZM26 21.5H28.5V19H26V21.5ZM28.5 16.5V19H31V16.5H28.5Z" fill="white"/></svg>') no-repeat bottom right #F8F8F8;
}

.awardItemType {
    color: var(--color-grey-800, #3E3E3E);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
}

.awardItemTime {
    color: var(--color-grey-800, #3E3E3E);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    border-radius: 4px;
    padding: 6px;
    border: 1px dashed #DDBE90;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.selectLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 12px 4px 16px;
    border-radius: 4px;
    border: 1px solid var(--color-grey-400, #E9E9E9);
    margin-bottom: 16px;
    gap: 8px
}

.selectTitle {
    margin-right: auto;
    margin-left: 8px;
    color: var(--color-grey-700, #818181);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.408px;
}

.selectInput {
    display: none;
}

.selectInput+span {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    border: 1px solid var(--color-grey-400, #E9E9E9);
    position: relative;
}

.selectInput:checked+span {
    border-color: #DDBE90;

    &::after {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 100%;
        position: absolute;
        display: block;
        top: 3px;
        left: 3px;
        background: #DDBE90;
    }
}

.menuItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 4px;
    align-items: center;
}

.modalSuccess {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.successModalIcon {
    margin-bottom: 32px;
}

.successModalHeading {
    color: var(--color-grey-900, #1E1E1E);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 4px;
}

.successModalTitle {
    color: var(--color-grey-900, #1E1E1E);
    text-align: center;
    font-feature-settings: 'clig'off, 'liga'off;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
}