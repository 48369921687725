.modalSuccess{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.errorModalIcon{
    margin-bottom: 32px;
    text-align: center;
}
.errorModalHeading{
    color: var(--color-grey-900, #1E1E1E);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 4px;
}
.errorModalTitle{
    color: var(--color-grey-900, #1E1E1E);
    text-align: center;
    font-feature-settings: 'clig'off, 'liga'off;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
}