.customSelect{
    height: 49px;
}

.selectorMobile {
    height: 48px;
    padding: 10px 25px 10px 10px;
    border-radius: 4px;
    border: 1px solid var(--color-grey-400, #E9E9E9);
    background: transparent;
    width: 100%;
    color: black;
    font-size: 16px;
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
    outline: none;
    position: relative;
}

.mobWrapper {
    position: relative;
    background-color: #fff;
    width: 100%;

    span {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: 14px;
        height: 8px;
        background-image: url('data:image/svg+xml,<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 5.09079C7 5.09069 7.00004 5.09059 7.00012 5.09052L11.7615 0.638772C12.146 0.27929 12.7433 0.279395 13.1277 0.639013L13.2193 0.724724C13.6418 1.12001 13.6416 1.79026 13.219 2.1854L7.68296 7.36145C7.29857 7.72084 6.70143 7.72084 6.31704 7.36145L0.781262 2.18566C0.358538 1.79042 0.358539 1.11998 0.781263 0.724742L0.872354 0.639574C1.25674 0.280185 1.85388 0.280185 2.23827 0.639575L6.99937 5.09107C6.99961 5.09129 7 5.09112 7 5.09079Z" fill="%23818181"/></svg>');
    }
}