.LinkBtn {
    color: var(--color-accent-first, #DDBE90);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.408px;
    text-decoration: none;

    &:hover{
        text-decoration: underline;
        cursor: pointer;
    }
}