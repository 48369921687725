.main {
  background: rgba(221, 190, 144, .15);
  padding: 1rem;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  color: var(--system-black, #000);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 91.667% */
  text-transform: uppercase;
}

.home {
  background: #F6F6F6;
  width: 100%;
}

.container {
  width: 1101px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 15px;
  }
}

.logoTop {
  @media (max-width: 1200px) {
    width: 113px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 0;
  border-bottom: 1px solid #898989;

}

.loginBtn a {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--color-accent-first, #DDBE90);
  color: var(--color-grey-900, #1E1E1E);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.footer {
  border-top: 1px solid #898989;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 16px
  }

  .copy {
    color: var(--color-grey-800, #3E3E3E);
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 6px;

    @media (max-width: 1200px) {
      align-items: center;
    }

    a {
      color: var(--color-grey-800, #3E3E3E);
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      text-decoration: underline;
      text-decoration-color: #DDBE90;
      margin-bottom: 16px;

      @media (max-width: 1200px) {
       margin-bottom: 8px;
      }
    }
  }
}

img,
video {
  @media (max-width: 1200px) {
    // width: 100%;
    // height: auto;
    margin: 0 auto;
  }
}

.hero {
  display: flex;
  align-items: center;
  gap: 40px;

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 24px
  }

  h1 {
    color: var(--color-accent-first, #DDBE90);
    font-family: Rubik;
    font-size: 56px;
    font-weight: 600;
    line-height: 70px;
    text-align: left;
    color: #000000;
    margin-bottom: 40px;
    text-transform: uppercase;


    @media (max-width: 1200px) {
      font-family: Rubik;
      font-size: 32px;
      font-weight: 600;
      line-height: 38px;
      text-align: center;
      color: #000000;
      margin-top: 35px;
      text-transform: uppercase;
    }
  }

  p {
    font-family: Rubik;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: #898989;
    margin-bottom: 40px;

    @media (max-width: 1200px) {
      font-family: Rubik;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      color: #898989;
      margin-bottom: 0;
    }
  }
}

.mobHide {
  @media (max-width: 1200px) {
    display: none;
  }
}

.mobShown {
  display: none;

  @media (max-width: 1200px) {
    display: block;
    margin:0;
    position: relative;
    bottom: 60px
  }
}

.heroR {
  @media (max-width: 1200px) {
    display: none;
  }
}

.heroRM {
  display: none;

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  @media (max-width: 1200px) {
    display: block;
  }
}

.heroBtn {
  color: var(--color-white, #FFF);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  width: 292px;
  padding: 13px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #DB7949;
  background: #DB7949;
}

.plr,
.pll {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 50px;

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 16px
  }
}

.pll {
  @media (max-width: 1200px) {
    flex-direction: column-reverse;
  }
}

.plT {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.plH {
  color: #0C1135;
  font-family: Rubik;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  display: inline-flex;
  text-align: left;
  margin-bottom: 32px;

  >svg {
    min-width: 40px;
  }

  @media (max-width: 1200px) {
    color: #0C1135;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    margin-bottom: 0;
  }
}

.prg {
  font-family: Rubik;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  margin-bottom: 60px;

  @media (max-width: 1200px) {
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;

  }
}

.plP {
  color: #898989;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;

  @media (max-width: 1200px) {
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;

  }
}

.plI,
.prI {
  display: none;
}

.pl {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 100px;

  >* {
    flex-basis: 100%;
    max-width: 49%;
    box-shadow: -2px 2px 9px 0px #E9E9E9;
    border: 1px solid #E9E9E9;
    background: #F3F4F5;
    border-radius: 12px;
    padding: 39px;
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 480px;

    @media (max-width: 1200px) {
      width: 100%;
      max-width: unset;
      min-height: 266px;
      max-height: 266px;

      &:nth-child(1) {
        background-size: 120px 199px;
      }

      &:nth-child(2) {
        background-size: 315px 112px;
      }

      &:nth-child(3) {
        background-size: 167px 226px;
      }

      &:nth-child(4) {
        background-size: 271px 206px;
      }
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.fakeSearch {
  border: 1px solid #E9E9E9;
  box-shadow: -2px 2px 9px 0px #E9E9E9;
  display: flex;
  gap: 16px;
  border-radius: 4px;
  padding: 15px 12px;
  align-items: center;
  font-family: Rubik;
  font-size: 24px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  margin-bottom: 30px;
}

.nft {
  width: 980px;
  margin: 0 auto;
  margin-bottom: 100px;

  @media (max-width: 1200px) {
    width: 100%;
  }
}

.fInput {
  border: none;
  box-shadow: none;
  line-height: 16px;
  width: 100%;
  font-family: Rubik;
  font-size: 24px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  background-color: white;
  color: black;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &::placeholder {
    color: #000;
  }
}

.fakeResults {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  img {
    @media (max-width: 1200px) {
      width: 49%;
      height: auto;
      display: block;
    }
  }
}

.h2 {
  color: var(--color-grey-900, #1E1E1E);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;

  @media (max-width: 1200px) {
    color: var(--color-grey-900, #1E1E1E);
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    
  }
}


.h21 {
  text-align: center;

  @media (max-width: 1200px) {
    text-align: left;
  }
}

.psdSvg {
  @media (max-width: 1200px) {
    stroke-width: 1px;
    stroke: var(--color-grey-700, #818181);
  }
}

.psd {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 32px 0 16px;
}

.psdP {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--color-grey-700, #818181);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.408px;
  border-radius: 4px;
  background: #F5F6F7;

  @media (max-width: 1200px) {
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--color-grey-700, #818181);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
    letter-spacing: -0.408px;
    border-radius: 4px;
    background: #F5F6F7;
  }
}

.psdItems {
  display: flex;
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 88px;

  @media (max-width: 1200px) {
    overflow-x: auto;
  }
}

.psdItem {
  display: flex;
  padding: 16px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: #F5F6F7;
  background-repeat: no-repeat;
  color: var(--color-grey-700, #818181);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  letter-spacing: -0.408px;
  min-width: 240px;
}

.psdItemBottom {
  display: flex;
  flex-direction: column;
  gap: 8px
}

.psdItemBottomIndex {
  display: flex;
  gap: 4px;
  align-items: center;
}

.subT {
  color: var(--color-grey-800, #3E3E3E);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;

  @media (max-width: 1200px) {
    color: var(--color-grey-800, #3E3E3E);
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    width: 100%;
    text-align: center;
  }
}

.videoBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  padding-bottom: 72px;
  gap: 24px;

  @media (max-width: 1200px) {
    align-items: flex-start;
  }
}

.banner {
  display: flex;
  padding: 80px 248px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  background: #F5F6F7;
  text-align: center;

  @media (max-width: 1200px) {
    padding: 48px 0;
  }
}

.splashWhite {
  background-color: #FFF;
  padding-top: 50px;
}

.splashH2 {
  font-family: Rubik;
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  text-align: center;
  color: #000;
  margin-bottom: 50px;
  text-transform: uppercase;

  span {
    color: #DDBE90;
  }

  @media (max-width: 1200px) {
    font-family: Rubik;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    margin-bottom: 20px;
  }
}

.fBottom {
  max-width: 900px;
  text-align: center;
  margin: 60px auto 0;
  padding-bottom: 30px;

  @media (max-width: 1200px) {
    margin-top: 10px;
  }

  p {
    margin-bottom: 16px;
    font-family: Rubik;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;


    a {
      text-decoration: underline;
    }
  }
}

.realSearch {
  max-width: 900px;
  margin: 40px auto 60px;

  form {
    width: 100%;
    display: flex;
    gap: 16px;
    position: relative;

    @media (max-width: 1200px) {
      flex-direction: column;
      gap: 16px
    }
  }

  .realSearchText {
    border: 1px solid #E9E9E9;
    box-shadow: -2px 2px 9px 0px #E9E9E9;
    border-radius: 4px;
    padding: 15px 140px 15px 12px;
    font-family: Rubik;
    font-size: 24px;
    font-weight: 400;
    line-height: 22px;
    flex-basis: 100%;

    &::placeholder{
      opacity: .5;
    }

    @media (max-width: 1200px) {
      font-size: 16px;
    }
  }

  .realSearchSubmit {
    position: absolute;
    right: 0;
    padding: 18px 32px;
    gap: 10px;
    border-radius: 4px;
    border: 1px 0px 0px 0px;
    background-color: #DDBE90;
    border: 1px solid #DDBE90;
    font-family: Rubik;
    font-size: 24px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #FFF;

    @media (max-width: 1200px) {
      font-family: Rubik;
      font-size: 16px;
      font-weight: 400;
      line-height: 12px;
      text-align: left;
      height: 54px;
      text-align: center;
    }
  }
}