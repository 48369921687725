@mixin btn() {
    display: flex;
    padding: 13px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    width: 100%;
    &:hover{
        cursor: pointer
    }

    &:disabled{
        opacity: .7;
        cursor: default;
    }
}

.btnPrimary {
    border-radius: 4px;
    border: 1px solid var(--color-accent-first, #DDBE90);
    background: var(--color-accent-first, #DDBE90);
    color: var(--color-white, #FFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    @include btn()
}

.btnSecondary {
    border-radius: 4px;
    border: 1px solid var(--color-accent-first, #DDBE90);
    background-color: white;
    color: var(--color-grey-900, #1E1E1E);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
    @include btn()
}