@import '../../../styles/mixins';

.mnInput {
    display: flex;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid var(--color-grey-400, #E9E9E9);
    background: #FFF;
    align-items: center;
    position: relative;
    width: 100%;
}

.icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input {
    width: 100%;
    background: transparent;
    border: none;
    box-shadow: none;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.408px;
    outline: none;
    z-index: 2;
    position: relative;

    @include placeholder(#CDCDCD);

    @media (min-width: 768px){
        font-size: 14px;
    }

    &+span {
        color: var(--color-grey-700, #818181);
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px; /* 120% */
        letter-spacing: -0.408px;
        border-radius: 2px;
        border: 1px solid var(--color-grey-400, #E9E9E9);
        background: #FFF;
        top: -7px;
        padding: 2px;
        position: absolute;
        z-index: 1;
        @include transition();
    }

    &:not(:focus):placeholder-shown + span {
        color: var(--color-grey-500, #CDCDCD);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        top: 9px;
        border-color: transparent;
    }
}

.textarea{
    min-height: 50px;
}