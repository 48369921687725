.loginWrapper {
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    max-width: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.registerWrapper {
    width: 100vw;
    height: 100vh;
    min-height: -webkit-fill-available;
    margin: 0 auto;
    position: relative;
    padding: 16px;
    max-width: 500px;
    overflow: hidden;
}

.h1 {
    color: #DDBE90;
    text-align: center;
    font-feature-settings: 'clig'off, 'liga'off;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    /* 143.75% */
    letter-spacing: 0.5px;
    text-transform: uppercase;
    padding: 16px;

    @media(min-height: 800px) {
        padding: 16px;
    }
}

.lofinForm {
    position: relative;
    bottom: 0;
    padding: 16px;
    border-radius: 20px 20px 0px 0px;
    border: 1px solid var(--color-grey-400, #E9E9E9);
    background: #FFF;
    /* shadow/--main-shadow */
    box-shadow: 0px -4px 24px 0px rgba(144, 144, 144, 0.05);
    width: 100%;
    box-sizing: border-box;
}

.loginImg {
    display: flex;
    width: 100%;
    height: 100%;
    flex-basis: 100%;
    align-items: center;
    justify-content: center;

    // img{
    //     max-height: 100%;
    // }
    @media(max-height: 767px) {
        display: none;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.h2 {
    color: var(--system-black, #000);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}

.gIcon {
    margin-left: auto;
}

.yIcon {
    margin-left: 12px;
}

.switcher {
    color: var(--color-grey-700, #818181);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.408px;
    margin-top: 24px;
    margin-bottom: 16px;
}

.stepButton {
    display: flex;
    gap: 16px;
}

.stepsFooter {
    width: calc(100% - 32px);
    bottom: 16px;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 15px;
    max-width: 500px;
    background-color: white;

    @media (min-width: 1000px) {
        position: relative;
        width: 100%;
        bottom: 0;
        margin-top:16px
    }
}

.categoryLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 12px 4px 16px;
    border-radius: 4px;
    border: 1px solid var(--color-grey-400, #E9E9E9);
    margin-bottom: 16px;
}

.categoryTitle {
    margin-right: auto;
    margin-left: 8px;
    color: var(--color-grey-700, #818181);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.408px;
}

.categoryInput {
    display: none;
}

.categoryInput+span {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    border: 1px solid var(--color-grey-400, #E9E9E9);
    position: relative;
}

.categoryInput:checked+span {
    border-color: #DDBE90;

    &::after {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 100%;
        position: absolute;
        display: block;
        top: 3px;
        left: 3px;
        background: #DDBE90;
    }
}

.profilePromoBlock {
    border-radius: 4px;
    border: 1px dashed var(--color-accent-first, #DDBE90);
    padding: 8px;
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 16px;
}

.profilePromoCat {
    border-radius: 38px;
    border: 1px solid var(--color-grey-400, #E9E9E9);
    background: #FFF;
    min-width: 32px;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 32px;
    max-height: 32px;
}

.profilePromoText {
    color: var(--color-grey-700, #818181);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 150% */
}

.profileForm {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    border: 1px solid var(--color-grey-400, #E9E9E9);
}

.profileAvatar {
    display: flex;
    width: 98px;
    height: 98px;
    padding: 28px;
    justify-content: center;
    align-items: center;
    border-radius: 77px;
    border: 1px solid var(--color-accent-first, #DDBE90);
    overflow: hidden;
}

.userAvatar {
    border-radius: 50%;
    object-fit: cover;
}

.LinkBtn {
    color: var(--color-accent-first, #DDBE90);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.408px;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.registrationStep5 {
    max-height: calc(100% - 58px);
    overflow-y: auto;
}

.banner {
    display: flex;
    width: 100%;
    padding: 16px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid var(--color-grey-400, #E9E9E9);
    background: rgb(241 239 255 / 60%);
    top: 20px;
    left: 50%;
    max-width: 358px;
    transform: translateX(-50%);
    position: absolute;
}

.bannerText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.bannerTextTitle {
    color: var(--color-grey-800, #3E3E3E);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 112.5% */
}

.bannerTextDesc {
    color: var(--color-grey-800, #3E3E3E);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
}

.bannerTextButton {
    a {
        display: flex;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        background: #695FD1;
        color: var(--color-white, #FFF);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
    }
}

.bannerClose {
    position: absolute;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 10px;
    top: 10px;
}

.resetTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 16px;
}

.error {
    font-size: 12px;
    color: red;
    margin-bottom: 8px;
    display: block;
    line-height: 18px;
}

.hnuT {
    color: var(--color-grey-900, #1E1E1E);
    font-feature-settings: 'clig'off, 'liga'off;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 16px;
}
.hnuD{
    color: var(--color-grey-900, #1E1E1E);
font-family: Rubik;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 15px;
margin-bottom: 16px;
}