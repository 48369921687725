@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600&display=swap');
:root {
  --max-width: 1100px;
  --border-radius: 12px;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 255, 255, 255 --background-end-rgb: 255, 255, 255;

  --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
  --secondary-glow: linear-gradient(to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3));

  --tile-start-rgb: 2, 13, 46;
  --tile-end-rgb: 2, 5, 19;
  --tile-border: conic-gradient(#ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80);

  --callout-rgb: 20, 20, 20;
  --callout-border-rgb: 108, 108, 108;
  --card-rgb: 100, 100, 100;
  --card-border-rgb: 200, 200, 200;
  background-color: white;
  font-family: 'Rubik', sans-serif;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 255, 255, 255 --background-end-rgb: 255, 255, 255;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(to bottom right,
        rgba(1, 65, 255, 0),
        rgba(1, 65, 255, 0),
        rgba(1, 65, 255, 0.3));

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(#ffffff80,
        #ffffff40,
        #ffffff30,
        #ffffff20,
        #ffffff10,
        #ffffff10,
        #ffffff80);

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

h2 {
  color: var(--system-black, #000);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: 'Rubik', sans-serif;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(to bottom,
      transparent,
      rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

body * {
  font-family: 'Rubik', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

body.dark-mode {
  color-scheme: dark;
}

.ant-select-selector {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 0 13px !important;
}

.profileDropDown {
  &.ant-dropdown {
    .ant-dropdown-menu {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.40);
      backdrop-filter: blur(27.182817459106445px);
      padding: 5px 0;

      .ant-dropdown-menu-item {
        color: #FFF;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 10px;
      }

      .ant-dropdown-menu-item-divider {
        background: rgba(255, 255, 255, 0.60);
      }
    }
  }
}

.ant-dropdown-menu-item-disabled {
  display: none !important;
}

.noprogreess .ant-upload-list {
  display: none !important;
}

.form-error {
  color: red;
  font-size: 14px;
}

.albumPhotos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 8px 8px;
  grid-auto-flow: row;
  grid-template-areas:
    "image1 image2"
    "image3 image4"
    "image3 image5"
    "image6 image6"
    ". .";
}

.albumPhotosPhoto-1 {
  grid-area: image1;
  border-radius: 4px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.albumPhotosPhoto-2 {
  grid-area: image2;
  border-radius: 4px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.albumPhotosPhoto-3 {
  grid-area: image3;
  border-radius: 4px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.albumPhotosPhoto-4 {
  grid-area: image4;
  border-radius: 4px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.albumPhotosPhoto-5 {
  grid-area: image5;
  border-radius: 4px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.albumPhotosPhoto-6 {
  grid-area: image6;
  border-radius: 4px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.ant-spin .ant-spin-dot-item{
  background-color: #DDBE90 !important
}
.ant-select-single, 
.ant-picker, 
.ant-select-dropdown, 
.ant-picker .ant-picker-input >input, 
.ant-select-single .ant-select-selector, 
.ant-select-single .ant-select-selector input,
*:not(:focus):placeholder-shown + span {
  font-size: 16px !important;
  @media (min-width: 768px){
    font-size: 14px !important;
}
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner{
  background-color: #DDBE90 !important
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner{
  border-color: #DDBE90 !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #DDBE90 !important;
  border-color: #DDBE90 !important;
}
.ant-checkbox{
  align-self: auto;
}