$transition-base: .3s cubic-bezier(.25, .1, .25, 1);
@mixin transition {
    transition: $transition-base;
}

/* Миксин замены цвета placeholder у input.
  Нужно добавить в стилях элемента конструкцию вида @include placeholder(#000); */

@mixin placeholder($color) {
    &::-webkit-input-placeholder {
        color: $color;
    }
    &::-moz-placeholder {
        color: $color;
    }
    &:-ms-input-placeholder {
        color: $color;
    }
}